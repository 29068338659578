<template>
    <base-modal :footer="true" mode="large custom-dialog" classes="terminal-modal large">
        <div>
            <div class="checkout-overview">
                <div class="checkout-overview-left">
                    <next-steps :products="products"></next-steps>
                </div>
                <div class="checkout-overview-right">
                    <order-summary :products="products" :removeHandler="removeProduct"></order-summary>
                </div>
            </div>
        </div>
        <template slot="footer">
            <div style="color: white; margin-top: 10px; margin-bottom: 5px">
                <b-row cols="12">
                    <div offset="4" offset-xl="3" xl="7" cols="8">
                        <b-row cols="12">
                            <div cols="8" xl="8">
                                <b-form-checkbox
                                        v-model="model.termsAndConditions"
                                >
                                    I agree to the <a
                                        style="color: white; "
                                        href="https://financialsource.co/terms-conditions/"
                                        target="_blank">terms and conditions</a>
                                </b-form-checkbox>
                            </div>
                            <div xl="1" cols="1" ><span style="text-align: right">${{ model.totalWithVat }}</span></div>
                            <div xl="3" cols="3" style="align-content: end">
                                <b-button block style="padding: 5px" @click="makePayment" variant="success">Make Payment</b-button>
                            </div>
                        </b-row>
                    </div>
                </b-row>
            </div>
        </template>
    </base-modal>
</template>

<script>
import OrderSummary from "./OrderSummary";
import NextSteps from "./NextSteps";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import _ from 'lodash';
import {toSystemPrice} from "../../../utility/helper-functions";
import Swal from "sweetalert2";

export default {
    name: "CheckoutOverview",
    components: {NextSteps, OrderSummary},
    mixins: [SubscriptionMixin],
    props: {
        selectedItem: {
            type: Object,
            default: () => {
            }
        },
        type: {
            type: String
        }
    },
    data: function () {
        return {
            products: [],
            model: {
                total: 0,
                vat: 0,
                totalWithVat: 0,
                termsAndConditions: false
            }
        }
    },
    watch: {
        products: {
            deep: true,
            handler: function () {
                let total = 0;
                this.products.forEach(product => {
                    total += product.updatedPrice;
                })
                this.model.total = toSystemPrice(total);
                this.model.vat = toSystemPrice(this.calculateVat(total));
                this.model.totalWithVat = toSystemPrice(this.calculatePriceWithVat(total));
            }
        }
    },
    methods: {
        addProduct: function (product) {
            this.products.push(product);
        },
        removeProduct: function (product) {
            this.products.splice(this.products.findIndex(item => item.slug === product.slug), 1);
        },
        makePayment: function () {
            if (this.model.termsAndConditions) {
                if (!this.hasActiveCard) {
                    this.$toast.warning(
                        '<div><span style="color: #393636">There is no payment method</span>' +
                        '<label style="color: #393636">Please add Payment Method in Billing Details</label></div>', {
                            position: 'bottom'
                        })
                    return;
                }
                this.products.forEach(product => {
                    if (product.product_type === "addon") {
                        this.upgradePromotion(product.planDetails.addonProducts[0]);
                    } else {
                        this.upgradePromotion(product);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Payment Info!',
                    type: 'warning',
                    text: 'You have to accept the terms and conditions!',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
            }
        }
    },
    mounted() {
        let initialProduct = {
            ..._.cloneDeep(this.selectedItem),
            product_type: this.type,
            updatedPrice: this.type === "addon" ? this.selectedItem.planDetails.priceNumeric : parseFloat(this.selectedItem.pricing["USD"]),
            currentPrice: this.type === "addon" ? this.selectedItem.planDetails.priceNumeric : parseFloat(this.selectedItem.pricing["USD"])
        }
        this.products.push(initialProduct);
    }
}
</script>

<style lang="scss">
    .checkout-overview {

        // @include respond(lg) {
        //     display: grid;
        //     gap: 30px;
        //     grid-template-columns: 300px 1fr;
        // }

        // @include respond(xl) {
        //     grid-template-columns: 400px 1fr 200px;
        // }
    }
</style>
